import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { SignInConsumer, SignInSuccessCallback } from '../providers/SignInProvider'
import SignInForm from '../../users/sign_in/SignInForm'
import { User } from '../../../api/Users/Types'
import UserService from '../../../api/Users/Service'
import SimpleModal from './SimpleModal'
import Loading from '../Loading'
import ModalContent from './ModalContent'

interface Props {
  readonly currentUser?: User
  readonly modalIsOpen?: boolean
  readonly closeModal?: () => void
  readonly successCallback?: SignInSuccessCallback
}

/**
 * This modal component needs to have the
 * SignInProvider's context in order to consume it.
 * This is done to prevent props drilling.
 */
const SignInModal: React.FunctionComponent<Props> = ({
  currentUser,
  modalIsOpen,
  closeModal,
  successCallback,
}) => {
  // This is required to prevent modal from opening when it isn't necessary (user already logged in, but not in the store)
  const [isModalOpened, setIsModalOpened] = useState(modalIsOpen)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSignIn, setIsLoadingSignIn] = useState(false)

  const onAfterOpen = async (): Promise<void> => {
    const possibleCurrentUser = currentUser || (await UserService.currentUser())

    if (possibleCurrentUser) {
      if (successCallback) {
        setIsLoading(true)

        if (closeModal) {
          closeModal()
        }

        await successCallback(possibleCurrentUser)
        setIsLoading(false)
      } else {
        window.location.reload()
      }
    } else {
      window.location.href = `/signup?return_to=${window.location.pathname}`
    }
  }

  useEffect(() => {
    if (modalIsOpen) {
      onAfterOpen().catch(console.error)
    } else {
      setIsModalOpened(false)
    }
  }, [modalIsOpen])

  const onSuccessCallback = async currentUser => {
    if (successCallback) {
      setIsLoadingSignIn(true)
      await successCallback(currentUser)
      if (closeModal) {
        closeModal()
      }
      setIsLoadingSignIn(false)
    }
  }

  return (
    <>
      {isLoading && (
        <SimpleModal isOpen>
          <ModalContent className='signin-modal-loading'>
            <Loading />
          </ModalContent>
        </SimpleModal>
      )}
      <Modal
        isOpen={isModalOpened}
        onAfterOpen={onAfterOpen}
        onRequestClose={closeModal}
        contentLabel='Signin Modal'
        className='react-modal signin-modal'
        overlayClassName='react-modal-overlay'
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
      >
        <SignInConsumer>
          {signInContext => (
            <SignInForm
              handleSubmit={signInContext.handleSubmit}
              handleOtpMethodSubmit={signInContext.handleOtpMethodSubmit}
              otpDeliveryMessage={signInContext.otpDeliveryMessage}
              otpMethods={signInContext.otpMethods}
              signInFirstStageToken={signInContext.signInFirstStageToken}
              isLoading={signInContext.isLoading || isLoadingSignIn}
              error={signInContext.error}
              closeModal={closeModal}
              successCallback={successCallback ? onSuccessCallback : undefined}
            />
          )}
        </SignInConsumer>
      </Modal>
    </>
  )
}

export default SignInModal
