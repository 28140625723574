import React, { useState } from 'react'
import I18n from '../../../../helpers/I18n'
import CountDownTimer from '../../../marketing/CountDownTimer'

interface Props {
  readonly onOtpMethodSubmit: (event: any) => void
  readonly durationInSeconds: number
}

/**
 * Renders a button to resend the OTP. Includes a countdown timer.
 *
 * @remarks
 * When changing the value of {@param durationInSeconds}, make sure it satisfies the throttled rate defined in the backend.
 */
const ResendOTPButton: React.FC<Props> = ({ onOtpMethodSubmit, durationInSeconds }) => {
  const [buttonActive, setButtonActive] = useState(false)

  const onClick = event => {
    setButtonActive(false)
    onOtpMethodSubmit(event)
  }

  return (
    <div className='otp-resend-button-wrapper'>
      <button onClick={onClick} disabled={!buttonActive} type='button'>
        {I18n.t('views.devise.sessions.new.form.resend_otp')}
        {!buttonActive && (
          <>
            <span>{I18n.t('views.devise.sessions.new.form.resend_otp_in')}</span>
            <CountDownTimer
              callBack={() => setButtonActive(true)}
              durationInSeconds={durationInSeconds}
              showSeconds={true}
              onlySeconds={true}
            />
          </>
        )}
      </button>
      <div className='otp-resent-countdown-wrapper'></div>
    </div>
  )
}

export default ResendOTPButton
