import React from 'react'
import { stringifyInput } from '../../../helpers/StringHelper'

interface ModalHeaderProps {
  closeModal?: () => void
  className?: string
}

interface ModalBodyProps {
  className?: string
}

interface ModalFooterProps {
  includeArrow?: boolean
  className?: string
}

interface ModalContentCompound {
  Header: React.FC<ModalHeaderProps>
  Body: React.FC<ModalBodyProps>
  Footer: React.FC<ModalFooterProps>
}

interface ModalContentProps {
  className?: string
}

const ModalContent: React.FC<ModalContentProps> & ModalContentCompound = ({
  className,
  children,
}) => {
  return (
    <div className={`modal-content modal-content-component ${className || ''}`}>{children}</div>
  )
}

const Header: React.FC<ModalHeaderProps> = ({ closeModal, className, children }) => {
  return (
    <div className={`modal-header ${className || ''}`}>
      {children}
      {closeModal && (
        <button type='button' onClick={closeModal} className='close' aria-label='Close'>
          <span aria-hidden='true' className='icon-close'></span>
        </button>
      )}
    </div>
  )
}

const Body: React.FC<ModalBodyProps> = ({ className, children }) => {
  return <div className={`modal-body ${className || ''}`}>{children}</div>
}

const Footer: React.FC<ModalFooterProps> = ({ includeArrow, className, children }) => {
  return (
    <div className={`modal-footer ${stringifyInput('modal-footer-arrow')} ${className || ''}`}>
      {includeArrow && (
        <svg xmlns='http://www.w3.org/2000/svg'>
          <polygon points='0,0 10,10 20,0' style={{ fill: 'white' }} />
        </svg>
      )}
      {children}
    </div>
  )
}

ModalContent.Header = Header
ModalContent.Body = Body
ModalContent.Footer = Footer

export default ModalContent
