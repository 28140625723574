import React from 'react'

interface Props {
  isOpen: boolean
  onClose?: () => void
  className?: string
}

const SimpleModal: React.FC<Props> = props => {
  if (!props.isOpen) {
    return null
  }

  const onBackdropClick = event => {
    event.stopPropagation()
    if (event.target.className.includes('simple-modal') && props.onClose) {
      props.onClose()
    }
  }

  return (
    <div className={`simple-modal ${props.className || ''}`} onClick={onBackdropClick}>
      {props.children}
    </div>
  )
}

export default SimpleModal
