import React, { createContext } from 'react'
import { OtpMethod, OtpMethods, User } from '../../../api/Users/Types'
import UserService from '../../../api/Users/Service'
import { v4 as uuid } from 'uuid'

export type SignInSuccessCallback = (currentUser?: User) => void
export interface SignInArgs {
  email: string
  password?: string
  rememberMe: boolean
  otpAttempt?: string
  signInFirstStageToken?: string
}
export type SignInFunction = (
  args: SignInArgs,
  successCallback?: SignInSuccessCallback
) => Promise<void>

export interface OtpDeliveryFunctionArgs {
  email: string
  signInFirstStageToken?: string
  otpMethod: OtpMethod
}

export type OtpDeliveryFunction = ({
  email,
  signInFirstStageToken,
  otpMethod,
}: OtpDeliveryFunctionArgs) => Promise<void>

const newSessionEventName = 'new_user_context_created'

export interface SignInContextType {
  readonly currentUser?: User
  readonly isLoading: boolean
  readonly error?: string
  readonly handleSubmit: SignInFunction
  readonly otpMethods?: OtpMethods
  readonly signInFirstStageToken?: string
  readonly handleOtpMethodSubmit: OtpDeliveryFunction
  readonly otpDeliveryMessage?: string
}

const defaultContextValues: SignInContextType = {
  currentUser: undefined,
  isLoading: false,
  handleSubmit: () => new Promise(resolve => resolve()),
  otpMethods: undefined,
  signInFirstStageToken: undefined,
  handleOtpMethodSubmit: () => new Promise(resolve => resolve()),
}

export const SignInContext = createContext<SignInContextType>(defaultContextValues)
SignInContext.displayName = 'SignInContext'

export const SignInConsumer = SignInContext.Consumer

interface State {
  readonly currentUser?: User
  readonly isLoading: boolean
  readonly error?: string
  readonly otpMethods?: OtpMethods
  readonly signInFirstStageToken?: string
  readonly otpDeliveryMessage?: string
}

interface Props {
  readonly currentUser?: User
}

class SignInProvider extends React.Component<Props, State> {
  private uuid: string

  constructor(props) {
    super(props)
    // make sure current user is not an empty object `{}` when passed in as a prop
    const possibleCurrentUser =
      this.props.currentUser && Object.keys(this.props.currentUser).length
        ? this.props.currentUser
        : undefined
    this.state = {
      isLoading: false,
      currentUser: possibleCurrentUser,
      otpMethods: undefined,
    }
    this.receiveNewSessionContext = this.receiveNewSessionContext.bind(this)
    this.dispatchEvent = this.dispatchEvent.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOtpMethodSubmit = this.handleOtpMethodSubmit.bind(this)
    this.uuid = uuid()

    if (possibleCurrentUser) {
      this.dispatchEvent(possibleCurrentUser)
    }
  }

  async componentDidMount(): Promise<void> {
    window.addEventListener(newSessionEventName, this.receiveNewSessionContext)
  }

  componentWillUnmount(): void {
    window.removeEventListener(newSessionEventName, this.receiveNewSessionContext)
  }

  /**
   * Responsible to update the state of the provider based on the basis that
   * a 'new_user_context_created' custom event has been dispatched by some other
   * SignInProvider component.
   * @param args custom event object, holds the `currentUser` and
   * `originator` (uuid) values.
   */
  receiveNewSessionContext(args): void {
    // Don't respond to the event fired off by self
    if (args.detail.originator !== this.uuid) {
      this.setState({
        currentUser: args.detail.currentUser,
      })
    }
  }

  /**
   * Dispatches a custom event ('new_user_context_created') for listening
   * providers.
   * @param currentUser a value representing the state of the currentUser
   * variable accross the component.
   */
  dispatchEvent(currentUser): void {
    window.dispatchEvent(
      new CustomEvent(newSessionEventName, {
        detail: { currentUser, originator: this.uuid },
      })
    )
  }

  /**
   * Responsible for making the sign in request to the api,
   * fetching the current user, dispatching a custom event, calling the
   * success callback method or redirecting the user to last location or `/dashboard/home`
   * @param obj { email: string, password: string, rememberMe: boolean }
   * @param successCallback callback after successful login, optional
   */
  handleSubmit = async (
    { email, password, rememberMe, otpAttempt, signInFirstStageToken }: SignInArgs,
    successCallback: ((responseData) => void) | undefined
  ): Promise<void> => {
    const { isLoading } = this.state
    if (isLoading) return

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    this.setState({ isLoading: true })
    try {
      const responseData = await UserService.userSignIn(
        { email, password, rememberMe, otpAttempt, signInFirstStageToken },
        timeZone
      )

      // handle login 401 error
      if (responseData.error) {
        const { error } = responseData
        const errorState: State = {
          isLoading: false,
          error: error.message,
        }
        this.setState(errorState)
        return
      } else {
        this.setState({
          error: undefined,
        })
      }

      if ('otp_methods' in responseData) {
        this.setState({
          isLoading: false,
          otpMethods: responseData.otp_methods,
          signInFirstStageToken: responseData.sign_in_first_stage_token,
        })
        return
      }

      const currentUser = responseData.current_user

      if (currentUser) {
        if (typeof successCallback === 'function') {
          this.setState({
            isLoading: false,
            currentUser,
          })
          this.dispatchEvent(currentUser)
          await successCallback(currentUser)
        } else {
          window.location.href = responseData.return_to ? responseData.return_to : '/dashboard/home'
        }
      } else {
        this.setState({
          isLoading: false,
          error: 'Something went wrong, try again',
          currentUser,
        })
      }
    } catch (error) {
      this.setState({ isLoading: false, error: error })
    }
  }

  async handleOtpMethodSubmit({
    email,
    otpMethod,
    signInFirstStageToken,
  }: OtpDeliveryFunctionArgs): Promise<void> {
    const { isLoading } = this.state
    if (isLoading) return

    this.setState({ isLoading: true })
    try {
      const responseData = await UserService.deliverOtp({ email, otpMethod, signInFirstStageToken })

      if (responseData.error) {
        this.setState({
          isLoading: false,
          error: responseData.error.message,
        })
        return
      } else {
        this.setState({
          isLoading: false,
          error: undefined,
          otpDeliveryMessage: responseData.message,
        })
      }
    } catch (error) {
      this.setState({ isLoading: false, error: error })
    }
  }

  render() {
    const { children } = this.props
    const {
      isLoading,
      error,
      currentUser,
      otpMethods,
      otpDeliveryMessage,
      signInFirstStageToken,
    } = this.state
    const contextValue: SignInContextType = {
      isLoading,
      currentUser,
      error,
      handleSubmit: this.handleSubmit,
      handleOtpMethodSubmit: this.handleOtpMethodSubmit,
      otpMethods,
      otpDeliveryMessage,
      signInFirstStageToken,
    }
    return <SignInContext.Provider value={contextValue}>{children}</SignInContext.Provider>
  }
}

export default SignInProvider
