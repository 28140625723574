import React, { useState, useEffect } from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import OtpInput from 'react-otp-input'
import InputValidator from '../../common/InputValidator'
import {
  OtpDeliveryFunction,
  SignInFunction,
  SignInSuccessCallback,
} from '../../common/providers/SignInProvider'
import UserService from '../../../api/Users/Service'
import I18n from '../../../helpers/I18n'
import { OtpMethod, OtpMethods } from '../../../api/Users/Types'
import ResendOTPButton from './otp/ResendOTPButton'

interface Props {
  readonly isLoading: boolean
  readonly error?: string
  readonly handleSubmit: SignInFunction
  readonly handleOtpMethodSubmit: OtpDeliveryFunction
  // When used inside a modal component
  readonly closeModal?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  // Callback to run after successful login if needed
  readonly successCallback?: SignInSuccessCallback
  readonly redirectTo?: string
  //when used on alternate login page, no modal
  readonly isLoginPage?: boolean
  readonly showSignUpButton?: boolean
  readonly forgotPasswordUrl?: string
  readonly signUpUrl?: string
  readonly otpMethods?: OtpMethods
  readonly signInFirstStageToken?: string
  readonly otpDeliveryMessage?: string
  prefilledEmail?: string
}

const SignInForm: React.FunctionComponent<Props> = ({
  handleSubmit,
  handleOtpMethodSubmit,
  isLoading,
  error,
  closeModal,
  successCallback,
  redirectTo,
  returnTo,
  isLoginPage,
  showSignUpButton = true,
  forgotPasswordUrl = '/users/password/new',
  signUpUrl = '/signup',
  otpMethods,
  signInFirstStageToken,
  otpDeliveryMessage,
  prefilledEmail,
}) => {
  const [email, setEmail] = useState(prefilledEmail || '')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [otpMethod, setOtpMethod] = useState<OtpMethod>('email')
  const [rememberMe, setRememberMe] = useState(false)

  const returnToUrl = returnTo ? `${signUpUrl}?return_to=${returnTo}` : signUpUrl

  const onSubmit = event => {
    event.preventDefault()
    handleSubmit(
      { email, password, rememberMe, otpAttempt: otp, signInFirstStageToken },
      successCallback
    ).then(() => setPassword(''))
  }

  const onOtpMethodSubmit = event => {
    event.preventDefault()
    handleOtpMethodSubmit({ email, signInFirstStageToken, otpMethod })
  }

  const updateEmail = event => setEmail(event.target.value)
  const updatePassword = event => setPassword(event.target.value)
  const updateRememberMe = () => setRememberMe(rememberMe => !rememberMe)

  const redirectIfAlreadyLoggedIn = async (): Promise<void> => {
    const currentUser = await UserService.currentUser()
    if (currentUser) {
      window.location.href = redirectTo || '/dashboard/home'
    }
  }

  const onVisibilityChange = (): void => {
    if (!document.hidden) {
      redirectIfAlreadyLoggedIn()
    }
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => window.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  return (
    <ValidatorForm id='login-form' onSubmit={onSubmit} instantValidate={false}>
      {closeModal && (
        <div className='modal-header'>
          <button type='button' className='close' onClick={closeModal} aria-label='Close'>
            <span aria-hidden='true' className='icon-close'></span>
          </button>
        </div>
      )}
      <div className='form-content-wrapper'>
        {!otpMethods && (
          <>
            {isLoginPage ? (
              <h1 className='get-started-heading alternate-heading'>
                {I18n.t('views.devise.sessions.new.form.login_page_title')} <span>Vention</span>
              </h1>
            ) : (
              <h1 className='get-started-heading'>
                {I18n.t('views.devise.sessions.new.form.title')}
              </h1>
            )}
            <InputValidator
              id='email-signin'
              type='email'
              name='email-signin'
              placeholder=' '
              onChange={updateEmail}
              value={email}
              validators={['required', 'isEmail']}
              errorMessages={[
                I18n.t('views.common.form.errors.required'),
                I18n.t('views.common.form.errors.invalid', { field: 'Email' }),
              ]}
              autoFocus={!prefilledEmail}
            >
              <label htmlFor='email-signin'>
                {I18n.t('views.devise.sessions.new.form.email.label')}
              </label>
            </InputValidator>
            <InputValidator
              id='password-signin'
              type='password'
              name='password-signin'
              placeholder=' '
              onChange={updatePassword}
              value={password}
              autoFocus={prefilledEmail}
              validators={['required', 'minStringLength: 8', 'maxStringLength: 72']}
              errorMessages={[
                I18n.t('views.common.form.errors.required'),
                I18n.t('views.common.form.errors.min_string_length', { number: 8 }),
                I18n.t('views.common.form.errors.max_string_length', { number: 72 }),
              ]}
            >
              <label htmlFor='password-signin'>
                {I18n.t('views.devise.sessions.new.form.password.label')}
              </label>
            </InputValidator>
            <div className='checkbox-forgot-password-wrapper'>
              <div className='checkbox-wrapper'>
                <input
                  type='checkbox'
                  className='checkbox'
                  id='signin-remember-me'
                  checked={rememberMe}
                  onChange={updateRememberMe}
                />
                <label className='checkbox-label' htmlFor='signin-remember-me'>
                  <span>{I18n.t('views.devise.sessions.new.form.remember_me')}</span>
                </label>
              </div>

              <a
                className='forgot-password'
                href={forgotPasswordUrl}
                data-test='forgotPasswordLink'
              >
                {I18n.t('views.devise.sessions.new.form.forgot_password')}
              </a>
            </div>
          </>
        )}

        {!!otpMethods && (
          <h2 className='two-fa-heading'>{I18n.t('views.devise.sessions.new.form.title_otp')}</h2>
        )}

        {
          // Show OTP Input
          otpDeliveryMessage && (
            <div className='otp-enabled'>
              <p>{otpDeliveryMessage}</p>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle='otp-container'
                inputStyle='otp-input'
                shouldAutoFocus={true}
                isInputNum={true}
                isDisabled={isLoading}
              />
              <ResendOTPButton onOtpMethodSubmit={onOtpMethodSubmit} durationInSeconds={30} />
            </div>
          )
        }

        {
          // OTP method submit
          otpMethods && !otpDeliveryMessage && (
            <div className='otp-methods'>
              {otpMethods.map((method, index) => (
                <div className='otp-method' key={index}>
                  <input
                    type='radio'
                    id={`otp-method-${index}`}
                    name='otp-method'
                    value={method.name}
                    checked={method.name === otpMethod}
                    onChange={() => setOtpMethod(method.name)}
                  />
                  <label htmlFor={`otp-method-${index}`}>{method.description}</label>
                </div>
              ))}
            </div>
          )
        }

        {error && <p className='signin-error'>{error}</p>}

        {
          // OTP submit
          otpMethods && !otpDeliveryMessage && (
            <button
              onClick={onOtpMethodSubmit}
              disabled={isLoading}
              className='otp-submit-button'
              type='button'
            >
              {!isLoading ? (
                I18n.t('views.devise.sessions.new.form.submit_otp')
              ) : (
                <i className='fas fa-spinner-third'></i>
              )}
            </button>
          )
        }

        {(!otpMethods || otpDeliveryMessage) && (
          <button disabled={isLoading} className='button button-success button-md' type='submit'>
            {!isLoading ? (
              I18n.t('views.devise.sessions.new.form.submit')
            ) : (
              <i className='fas fa-spinner-third'></i>
            )}
          </button>
        )}

        {showSignUpButton && (
          <p className='signup-text'>
            {I18n.t('views.devise.sessions.new.no_account')}&nbsp;
            <a href={returnToUrl} className='signup-link'>
              {I18n.t('views.devise.sessions.new.no_account_cta')}
            </a>
          </p>
        )}
      </div>
    </ValidatorForm>
  )
}

export default SignInForm
